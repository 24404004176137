import React, { useEffect, useState } from 'react'

import { Container, Row, Col } from 'reactstrap'
import axios from 'axios';
import { format, addDays } from 'date-fns'
import Layout from '../components/Layout'
import Seo from '../components/seo'
import Hero from '../components/Hero'
import ExplanationSection from '../components/ExplanationSection'
import SliderDebates from '../components/SliderDebates'
import DebatesBox from '../components/DebatesBox'
import PriceBox from '../components/PriceBox'
import { LOADER, GETURL } from '../utils/constants'

import '../scss/page-debates-in-english.scss'

const debatesPage = () => {
  const [isLoading, setLoading] = useState(true);
  const [weekDebates, setWeekDebates] = useState([])

  useEffect(() => {
    async function getWeekDebates() {
      try {
        const startDate = format(new Date(), 'dd/MM/yyyy')
        const endDate = format(addDays(new Date(), 14), 'dd/MM/yyyy')

        const requestWeekDebates = await axios.get(
          `${GETURL}/classes`, {
            params: {
              startDate,
              endDate,
            },
          },
        );
        // Primeiro: data = axios; Segundo: data : endpoint
        setWeekDebates(requestWeekDebates.data.data)
        setLoading(false);
      } catch (error) {
        console.log('Erro: ', error.message)
      }
    }

    getWeekDebates()
  }, [])

  return (
    <Layout>
      <Seo title="Debates in English | Talk of the Town" />

      <Hero text="Debates in English" />

      <ExplanationSection
        imageBackground={require('../images/conferencia.jpg')}
        full
        grayBackground
      >
        <h2 className="title">
          NOSSA METODOLOGIA: APRENDENDO A PENSAR EM INGLÊS
        </h2>
        <span className="subtitle">
          Venha pensar em inglês no Talk of the Town
        </span>
        <p>
          Os estudos mais modernos sobre educação comprovam que o
          aprendizado é um processo relacionado à emoção. Aprender envolve
          não apenas a autoestima do aluno mas também o interesse e senso
          de propósito em relação ao que está sendo estudado. A
          metodologia do Talk of the Town se baseia neste conceito. Aqui,
          a maneira como você fala inglês é tão importante quanto o que
          você tem a dizer. Trabalhamos a forma de expressão através do
          conteúdo. Nossos debates e as questões que eles levantam vão
          fazer você se emocionar, se envolver, sentir vontade de
          compartilhar sua história, dar a sua opinião, em inglês. A cada
          semana, você vai adquirindo novo vocabulário, ganhando
          confiança, usando a língua para se aprofundar e discutir
          assuntos relevantes à medida em que seus erros gramaticais mais
          frequentes vão sendo corrigidos. Você pode optar por debates em
          grupo ou aulas particulares.
        </p>
        <span className="cta-text">Escolha aqui!</span>
      </ExplanationSection>

      <section className="images-container">
        <Container fluid>
          <Row>
            <Col
              lg="6"
              style={{
                backgroundImage: `url(${require('../images/Imagem_2.jpeg')})`,
              }}
            />
            <Col
              lg="6"
              style={{
                backgroundImage: `url(${require('../images/imagem_7.jpeg')})`,
              }}
            />
          </Row>
        </Container>
      </section>

      <section className="pricing orange">
        <Container>
          <Row>
            <div className="col-12">
              <h2>Debates em grupo</h2>
              <p>
                Escolha aqui o seu plano e faça seu cadastro com
                tema, dia e hora do primeiro debate grátis, para
                avaliação mútua.
              </p>
            </div>
          </Row>
          <Row className="containerPrices">
            <PriceBox
              widthClass="col-12 col-lg-4"
              title="Plano Mensal"
              value="R$ 510,00"
              period=""
              buttonUrl="/register/?type=grupo&plan=mensal"
              buttonText="Fazer debate grátis"
            />
            <PriceBox
              widthClass="col-12 col-lg-4 center"
              title="Plano Trimestral"
              value="R$ 1470,00"
              period="à vista com desconto"
              buttonUrl="/register/?type=grupo&plan=trimestral"
              featured
              buttonText="Fazer debate grátis"
            />
            <PriceBox
              widthClass="col-12 col-lg-4"
              title="Debates Avulsos"
              value="R$ 170,00"
              period="sujeitos à disponibilidade de vaga"
              buttonUrl="/register/?type=grupo&plan=avulso"
            />
          </Row>
        </Container>
      </section>

      <section className="pricing gray">
        <Container>
          <Row>
            <Col lg="12">
              <h2>Aulas particulares</h2>
              <p>
                Funcionam com a mesma metodologia do sdebates em
                grupo, mas você é quem escolhe os temas. Inscreva-se
                aqui!
              </p>
            </Col>
          </Row>
          <div className="row containerPrices">
            <PriceBox
              widthClass="col-12 col-lg-4 offset-lg-4"
              title="Hora/aula"
              value="R$ 150,00"
              period=""
              buttonUrl="/register/?type=grupo&plan=mensal"
              buttonText="Inscreva-se"
            />
          </div>
        </Container>
      </section>
      <section className="steps">
        <Container>
          <Row>
            <Col lg="12">
              <h2>Debates in English Step by Step</h2>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col lg="3">
              <div className="step">
                <span className="bullet">1</span>
                <p>
                  Receba por e-mail material de leitura e video
                  para se informar sobre o tema.
                </p>
              </div>
            </Col>
            <Col lg="3">
              <div className="step">
                <span className="bullet">2</span>
                <p>
                  Acesse o link do meeting na hora marcada. Você
                  será recebido pelo professor moderador e
                  apresentado ao grupo.
                </p>
              </div>
            </Col>
            <Col lg="3">
              <div className="step">
                <span className="bullet">3</span>
                <p>
                  Após o debate, acesse em seu email o report com
                  a revisão gramatical. Você será oficialmente
                  incluído naquele grupo ou receberá a indicação
                  dos grupos mais adequados a seu perfil e nível
                  de inglês.
                </p>
              </div>
            </Col>
            <Col lg="3">
              <div className="step">
                <span className="bullet">4</span>
                <p>
                  Após o debate, acesse em seu email o report com
                  a revisão gramatical. Você será oficialmente
                  incluído naquele grupo ou receberá a indicação
                  dos grupos mais adequados a seu perfil e nível
                  de inglês.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <h2 className="mb-0">O primeiro debate é grátis.</h2>
            </Col>
          </Row>
        </Container>
      </section>

      <SliderDebates
        sectionTitle="New Debates - This Week e Coming Soon"
        buttonUrl="/debates-of-the-week/"
        buttonText="All debates of the week"
      >
        <Row>
          {isLoading && (
            <div className="loadingSpinner"><img src={LOADER} alt="Carregando" /></div>
          )}
          {weekDebates.length === 0 && (
            <p>Nenhum debate cadastrado essa semana!</p>
          )}
          {weekDebates.map((item) => {
            const {
              _id, get_image, date, title, description,
            } = item;
            return (
              <DebatesBox
                key={_id}
                widthClass="col-12"
                background={get_image}
                dateHour={format(new Date(date), 'dd/MM/yyyy')}
                title={title}
                description={description}
                buttonUrl={`single/${_id}`}
                buttonText="Saiba mais"
                wordLimit="300"
              />
            )
          })}
        </Row>
      </SliderDebates>
    </Layout>
  )
}

export default debatesPage
